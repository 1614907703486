<!-- icon - no events -->
<mat-icon
	*ngIf="status === 'unknown' || !status"
	style="color: lightgrey"
	matTooltip="{{ 'COMMUNICATIONS.unknown' | translate | capitalize }}"
	>done</mat-icon
>
<!-- icon - accepted -->
<mat-icon *ngIf="status === 'accepted'" style="color: #ffca28" matTooltip="{{ 'COMMUNICATIONS.accepted' | translate | capitalize }}"
	>done</mat-icon
>
<!-- icon - delivered -->
<mat-icon *ngIf="status === 'delivered'" style="color: #1faa00" matTooltip="{{ 'COMMUNICATIONS.delivered' | translate | capitalize }}"
	>done</mat-icon
>
<!-- icon - failed -->
<mat-icon
	*ngIf="status === 'failed' && error === 'unknown'"
	color="warn"
	matTooltip="{{ 'COMMUNICATIONS.failed' | translate | capitalize }}, {{ 'COMMUNICATIONS.unknown' | translate }}"
	>close</mat-icon
>
<mat-icon
	*ngIf="status === 'failed' && error === 'retry'"
	color="warn"
	matTooltip="{{ 'COMMUNICATIONS.failed' | translate | capitalize }}, {{ 'COMMUNICATIONS.retry' | translate }}"
	>sync_problem</mat-icon
>
<mat-icon
	*ngIf="status === 'failed' && error === 'wrongnumber'"
	color="warn"
	matTooltip="{{ 'COMMUNICATIONS.failed' | translate | capitalize }}, {{ 'COMMUNICATIONS.wrongnumber' | translate }}"
	>disabled_by_default</mat-icon
>
