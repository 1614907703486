import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ReleaseNote } from 'src/app/core/graphql/generated/gen-types';
import { TenantSettingService } from 'src/app/core/services/tenantSetting/tenant-setting.service';

@Component({
	selector: 'app-dialog-releasenotes',
	templateUrl: './dialog-releasenotes.component.html',
	styleUrls: ['./dialog-releasenotes.component.scss'],
})
export class DialogReleasenotesComponent {
	releaseNotes: ReleaseNote[];
	selectedReleaseNote: ReleaseNote;
	releaseNotesRead: string[] = [];

	constructor(private tenantSettingService: TenantSettingService, public dialogRef: MatDialogRef<DialogReleasenotesComponent>) {
		this.tenantSettingService.getReleaseNotes().valueChanges.subscribe((result) => {
			this.releaseNotes = result.data.releaseNotes;
		});
	}

	ngOnDestroy() {
		if (this.releaseNotesRead.length > 0) {
			this.tenantSettingService.markReleaseNoteAsRead(this.releaseNotesRead).subscribe((result) => {});
		}
	}

	showReleaseNoteDetails(releaseNote: ReleaseNote) {
		this.selectedReleaseNote = releaseNote;
	}

	unmarkAsRead(releaseNote: ReleaseNote) {
		this.releaseNotesRead = this.releaseNotesRead.filter((id) => id !== releaseNote.id);
	}

	markAsRead(releaseNote: ReleaseNote) {
		this.releaseNotesRead.push(releaseNote.id);
	}
	markAllAsRead() {
		this.releaseNotesRead = this.releaseNotes.map((releaseNote) => releaseNote.id);
		this.dialogRef.close();
	}

	isRead(releaseNote: ReleaseNote) {
		return this.releaseNotesRead.includes(releaseNote.id);
	}
}
