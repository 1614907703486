import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'src/app/core/graphql/generated/gen-types';
import { UserService } from 'src/app/core/services';
import { ResponsiveService, ScreenSize } from 'src/app/core/services/admin/responsive.service';
import { SmsService } from 'src/app/core/services/sms/sms.service';
import { TenantSettingService } from 'src/app/core/services/tenantSetting/tenant-setting.service';
import { SnackBar } from 'src/app/core/utility/snackBar';
import { DialogReleasenotesComponent } from 'src/app/features/dialog/dialog-releasenotes/dialog-releasenotes.component';
import { environment } from 'src/environments/environment';

@Component({
	templateUrl: './main-layout.component.html',
	styleUrls: ['./main-layout.component.scss'],
	animations: [
		trigger('slideInOut', [
			state(
				'in',
				style({
					transform: 'translateX(0)',
					opacity: 0.8,
				})
			),
			state(
				'out',
				style({
					transform: 'translateX(50px)',
					opacity: 0.2,
				})
			),
			transition('out <=> in', [animate('0.5s')]),
		]),
	],
})
export class MainLayoutComponent implements OnInit {
	@ViewChild('drawer') drawer: MatDrawer;
	@ViewChild('overlay') overlay: ElementRef;
	overlayTop: number = 82; // Initial top position
	private isDragging = false;
	private startY = 0;
	private startTop = 0;

	sideNavOpened = true;
	drawerMode = 'over';
	drawerState = 'out';
	mode: string = 'side';
	mobileRoute: boolean = false;
	xsmall: boolean = false;
	subscription: Subscription;
	releaseNotesCount: number;
	unreadSms: number;
	unreadSmsSubscription;
	isOperator = false;
	guards = environment.componentGuards;
	isMobile = false;

	constructor(
		private responsiveService: ResponsiveService,
		private router: Router,
		private snackBar: SnackBar,
		private tenantSettingService: TenantSettingService,
		private userService: UserService,
		private dialog: MatDialog,
		private smsService: SmsService
	) {
		this.responsiveService.viewMobileChanged.subscribe((isMobile) => {
			this.isMobile = isMobile;
			this.overlayTop = isMobile ? 162 : 82;
		});
		this.userService.userProfileChanged.subscribe((userProfile) => {
			this.isOperator = this.guards.dashboard.sms.includes(userProfile.role);
		});
		this.tenantSettingService.getTenantSetting().valueChanges.subscribe((result) => {
			this.subscription = result.data.tenantSetting.subscription;
		});
		this.tenantSettingService.getReleaseNotesCount().valueChanges.subscribe((result) => {
			this.releaseNotesCount = result.data.releaseNotesCount;
		});
	}

	startDrag(event: MouseEvent): void {
		this.isDragging = true;
		this.startY = event.clientY;
		this.startTop = this.overlayTop;
		event.preventDefault();
	}

	@HostListener('document:mousemove', ['$event'])
	onMouseMove(event: MouseEvent): void {
		if (this.isDragging) {
			const deltaY = event.clientY - this.startY;
			this.overlayTop = this.startTop + deltaY;
		}
	}

	@HostListener('document:mouseup', ['$event'])
	onMouseUp(event: MouseEvent): void {
		this.isDragging = false;
	}

	isDrawerVisible(): boolean {
		if (!this.drawer) return false;
		return this.drawer.opened;
	}

	ngOnDestroy() {
		if (this.unreadSmsSubscription) {
			this.unreadSmsSubscription.unsubscribe();
		}
	}

	ngOnInit() {
		// get sms count
		this.unreadSmsSubscription = this.smsService.getUnreadSmsCount().subscribe({
			next: (count) => {
				this.unreadSms = count;
				if (this.unreadSms > 0) {
					this.drawerState = 'in';
				}
				if (this.unreadSms === 0) {
					this.drawerState = 'out';
				}
			},
		});
		this.smsService.getSmsDrawerStatus().subscribe((status) => {
			if (this.drawer) {
				if (status) {
					this.drawer.open();
				} else {
					this.drawer.close();
				}
			}
		});
		// observe the viewport, responsive in case of Mobile
		this.responsiveService.XSmallChanged.subscribe(
			(xsmall) => {
				this.xsmall = xsmall;
				if (xsmall || this.mobileRoute) {
					this.mode = 'push';
					this.sideNavOpened = false;
				} else {
					this.mode = 'side';
					this.sideNavOpened = true;
				}
			},
			(err) => this.snackBar.openSnackBarError(err.message)
		);

		// observe the route and close sidenav when mobile route is taken
		if (this.router.url.includes('mobile')) {
			this.mobileRoute = true;
			this.mode = 'push';
			this.sideNavOpened = false;
		}

		this.router.events.subscribe(
			(event) => {
				if (event instanceof NavigationEnd) {
					if (event.url.includes('mobile')) {
						this.mobileRoute = true;
						this.mode = 'push';
						this.sideNavOpened = false;
					} else {
						this.mobileRoute = false;
						this.mode = this.xsmall ? 'push' : 'side';
						this.sideNavOpened = !this.xsmall;
					}
				}
			},
			(err) => this.snackBar.openSnackBarError(err.message)
		);
	}

	toggleMenu() {
		this.sideNavOpened = !this.sideNavOpened;
	}

	showReleaseNotes() {
		this.dialog.open(DialogReleasenotesComponent, { width: window.innerWidth <= 360 ? '95%' : '80%' });
	}

	toggleDrawerMode() {
		this.drawerMode = this.drawerMode === 'side' ? 'over' : 'side';
	}

	toggleDrawerStatus() {
		this.smsService.setSmsDrawerStatus(!this.drawer.opened);
	}

	warningMessage() {
		const toolbarHeight = 64;
		const messageHeight = 50;
		let margin = toolbarHeight;
		margin += this.releaseNotesCount ? messageHeight : 0;
		margin += this.subscription?.sms?.pctUsed >= 95 && !this.subscription?.sms?.ignoreWarning ? messageHeight : 0;

		return {
			releaseNotesWarning: this.releaseNotesCount,
			smsSubscriptionWarning: this.subscription?.sms?.pctUsed >= 95 && !this.subscription?.sms?.ignoreWarning,
			margin: `${margin}px`,
		};
	}
}
