<mat-toolbar>
	<div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; gap: 20px">
		<button mat-icon-button (click)="toggleMenu.emit()">
			<mat-icon style="color: #e1dfe6">menu</mat-icon>
		</button>
		<!-- {{ activeMenuItem?.title | translate | uppercase }} -->
		@if (!viewMobile) {
		<img src="/assets/images/logo_wit.png" style="height: 36px" />
		}
	</div>
	<span class="toolbar-spacer"></span>
	<div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; gap: 20px">
		<div *ngIf="!viewMobile && isOperator" style="display: flex; gap: 20px">
			<button mat-flat-button [routerLink]="['/voucher/new']" color="accent">
				<mat-icon>receipt</mat-icon>
				{{ 'APPLICATION.add_voucher' | translate | capitalize }}
			</button>
			<button mat-flat-button [routerLink]="['/booking/new']" color="accent">
				<mat-icon>perm_contact_calendar</mat-icon>
				{{ 'APPLICATION.add_booking' | translate | capitalize }}
			</button>
			<button mat-flat-button [routerLink]="['/flight/new']" color="accent">
				<mat-icon>flight_takeoff</mat-icon>
				{{ 'APPLICATION.add_flight' | translate | capitalize }}
			</button>
		</div>
		<div>{{ tenant | uppercase }}</div>
		<div style="display: flex; flex-direction: row; align-items: center">
			<button *ngIf="viewMobile && drawerStatus" mat-mini-fab (click)="toggleDrawerStatus()">
				<mat-icon aria-hidden="false" [matBadge]="unreadSms" matBadgeColor="accent" [matBadgeHidden]="unreadSms === 0">sms</mat-icon>
			</button>
			<button *ngIf="viewMobile && !drawerStatus" mat-icon-button (click)="toggleDrawerStatus()">
				<mat-icon
					style="color: #e1dfe6"
					aria-hidden="false"
					[matBadge]="unreadSms"
					matBadgeColor="accent"
					[matBadgeHidden]="unreadSms === 0"
					>sms</mat-icon
				>
			</button>
			<button *ngIf="!viewMobile" mat-icon-button (click)="getHelp()">
				<mat-icon style="color: #e1dfe6">help</mat-icon>
			</button>
			<button mat-icon-button [matMenuTriggerFor]="dropdownMenu">
				<mat-icon style="color: #e1dfe6">account_circle</mat-icon>
			</button>
		</div>
	</div>
</mat-toolbar>
<mat-menu #dropdownMenu x-position="before">
	<a mat-menu-item href="" (click)="logout()">
		<mat-icon>exit_to_app</mat-icon>
		<span>{{ 'APPLICATION.sign_out' | translate | capitalize }}</span>
	</a>
	<!-- SHOW RELEASE NOTES -->
	<a mat-menu-item (click)="showReleaseNotes()">
		<mat-icon>open_in_browser</mat-icon>
		<span>{{ 'VALIDATIONS.show_new_features' | translate | capitalize }}</span>
	</a>
	<div mat-menu-item>Version {{ currentAppVersion }}</div>
</mat-menu>
