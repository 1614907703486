<mat-card appearance="outlined" style="max-height: 100vh; overflow-y: auto">
	<mat-card-header>
		<mat-card-title>{{ 'FLIGHTS.edit_sms' | translate | capitalize }}</mat-card-title>
	</mat-card-header>
	<mat-chip-set *ngIf="getNumberOfLanguages() > 1" style="margin-left: 20px">
		<mat-chip *ngIf="languages.nl > 0" [selected]="langSelected === 'nl'" (click)="updateLang('nl')">nl ({{ languages.nl }})</mat-chip>
		<mat-chip *ngIf="languages.fr > 0" [selected]="langSelected === 'fr'" (click)="updateLang('fr')">fr ({{ languages.fr }})</mat-chip>
		<mat-chip *ngIf="languages.en > 0" [selected]="langSelected === 'en'" (click)="updateLang('en')">en ({{ languages.en }})</mat-chip>
		<mat-chip *ngIf="languages.de > 0" [selected]="langSelected === 'de'" (click)="updateLang('de')">de ({{ languages.de }})</mat-chip>
	</mat-chip-set>
	<mat-card-content class="mat-card-content">
		<!-- SMS Form -->
		<form [formGroup]="smsDetailForm">
			<div style="display: flex; flex-direction: column; justify-content: space-between; gap: 10px">
				<div style="display: flex">
					<mat-form-field appearance="outline" *ngIf="langSelected === 'nl'" style="min-width: 400px">
						<mat-label>SMS</mat-label>
						<textarea cdkTextareaAutosize minRows="4" matInput formControlName="smsNL"></textarea>
						<mat-hint style="font-size: 11"
							>{{ characterCount(smsDetailForm.get('smsNL').value, 'nl') }} chars left -
							{{ smsCount(smsDetailForm.get('smsNL').value, 'nl') }} sms</mat-hint
						>
					</mat-form-field>
					<mat-form-field appearance="outline" *ngIf="langSelected === 'fr'" style="min-width: 400px">
						<mat-label>SMS</mat-label>
						<textarea cdkTextareaAutosize minRows="4" matInput formControlName="smsFR"></textarea>
						<mat-hint style="font-size: 11"
							>{{ characterCount(smsDetailForm.get('smsFR').value, 'fr') }} chars left -
							{{ smsCount(smsDetailForm.get('smsFR').value, 'fr') }} sms</mat-hint
						>
					</mat-form-field>
					<mat-form-field appearance="outline" *ngIf="langSelected === 'en'" style="min-width: 400px">
						<mat-label>SMS</mat-label>
						<textarea cdkTextareaAutosize minRows="4" matInput formControlName="smsEN"></textarea>
						<mat-hint style="font-size: 11"
							>{{ characterCount(smsDetailForm.get('smsEN').value, 'en') }} chars left -
							{{ smsCount(smsDetailForm.get('smsEN').value, 'en') }} sms</mat-hint
						>
					</mat-form-field>
					<mat-form-field appearance="outline" *ngIf="langSelected === 'de'" style="min-width: 400px">
						<mat-label>SMS</mat-label>
						<textarea cdkTextareaAutosize minRows="4" matInput formControlName="smsDE"></textarea>
						<mat-hint style="font-size: 11"
							>{{ characterCount(smsDetailForm.get('smsDE').value, 'de') }} chars left -
							{{ smsCount(smsDetailForm.get('smsDE').value, 'de') }} sms</mat-hint
						>
					</mat-form-field>
				</div>
				<mat-chip-set>
					@for (chip of chipListSelected; track chip) {
					<mat-chip selected="true" (click)="chipClick(chip, langSelected)">{{ formatChip(chip) }}</mat-chip>
					}
				</mat-chip-set>
				<!-- only show toggle when up is enabled and it is not a locationContactSms -->
				<mat-slide-toggle *ngIf="upEnabled && data.smsTemplate != 'locationContactSms'" formControlName="up">{{
					'FLIGHTS.send_up_url_with_sms' | translate | capitalize
				}}</mat-slide-toggle>
			</div>
		</form>

		<!-- Example -->
		<h1 mat-dialog-title>{{ 'FLIGHTS.preview_sms' | translate | capitalize }}</h1>
		<div class="mat-elevation-z8 sms-message">
			<div style="display: flex; justify-content: space-between; align-items: center">
				<div style="display: flex; flex-direction: column"><strong>Contact</strong></div>
				<div style="display: flex; flex-direction: column; font-size: 11px">{{ formatToday() }}</div>
			</div>
			<div style="display: flex; justify-content: space-between; align-items: center">
				<div style="display: flex">
					<span style="font-style: italic"><strong>Jij:&nbsp;</strong></span>
					{{ parseTemplate(getSMSSelected(langSelected), data.flight, langSelected) }} {{ smsDetailForm.get('up').value ? exampleUp : '' }}
					{{ smsFooter ? smsFooter[langSelected] : '' }}
				</div>
				<div style="display: flex; align-items: center">
					<mat-icon class="mat-error-icon">sms_failed</mat-icon>
				</div>
			</div>
		</div>
	</mat-card-content>

	<mat-card-actions class="mat-card-actions">
		<button mat-flat-button (click)="confirm(smsDetailForm.value)" color="warn">
			{{ 'FLIGHTS.' + sendTo | translate | capitalize }}
		</button>
		<button *ngIf="smsReminder || smsCancel" mat-flat-button (click)="confirmToAll(smsDetailForm.value)" color="warn">
			{{ 'FLIGHTS.send_to_pax_and_crew' | translate | capitalize }}
		</button>
		<button mat-stroked-button (click)="cancel()">{{ 'APPLICATION.cancel' | translate | capitalize }}</button>
	</mat-card-actions>
</mat-card>
