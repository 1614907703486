<div style="margin: 20px">
	<div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px">
		<div style="display: flex; justify-content: left; align-items: center; gap: 10px">
			<mat-icon><span class="material-symbols-outlined"> news </span></mat-icon>
			<span style="font-size: large">Release Notes</span>
		</div>
		<div>
			<button mat-flat-button (click)="markAllAsRead()" color="accent">Mark all as read</button>
		</div>
	</div>
	<mat-accordion *ngIf="releaseNotes">
		@for (releaseNote of releaseNotes; track releaseNote) {
		<mat-expansion-panel (opened)="showReleaseNoteDetails(releaseNote)" hideToggle>
			<mat-expansion-panel-header>
				<mat-panel-title>
					{{ releaseNote.date | formatDate: 'DD MMM YYYY' }}
				</mat-panel-title>
				<mat-panel-description [ngStyle]="isRead(releaseNote) ? { 'text-decoration': 'line-through' } : {}">
					{{ releaseNote.oneliner }}
				</mat-panel-description>
				<mat-icon *ngIf="!isRead(releaseNote)" (click)="$event.stopPropagation(); markAsRead(releaseNote)"
					>check_box_outline_blank</mat-icon
				>
				<mat-icon *ngIf="isRead(releaseNote)" (click)="$event.stopPropagation(); unmarkAsRead(releaseNote)">check_box</mat-icon>
			</mat-expansion-panel-header>
			<div *ngIf="selectedReleaseNote">
				<div class="content">
					<h2>{{ selectedReleaseNote.date | date: 'mediumDate' }}</h2>
					<h3>{{ selectedReleaseNote.oneliner }}</h3>
					<markdown [data]="selectedReleaseNote.description"></markdown>
					<!-- <a [href]="selectedReleaseNote.documentation" target="_blank">Documentation</a> -->
					<div *ngIf="selectedReleaseNote.video">
						<youtube-player [videoId]="selectedReleaseNote.video"></youtube-player>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
		}
	</mat-accordion>
</div>
