import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Flight, SmsConversationType, SmsType } from 'src/app/core/graphql/generated/gen-types';
import { BookingService } from 'src/app/core/services/booking/booking.service';
import { FlightService } from 'src/app/core/services/flight/flight.service';
import { SmsService } from 'src/app/core/services/sms/sms.service';
import { DialogSmsComponent } from 'src/app/features/dialog/dialog-sms/dialog-sms.component';

@Component({
	selector: 'app-dashboard-sms',
	templateUrl: './dashboard-sms.component.html',
	styleUrls: ['./dashboard-sms.component.scss'],
})
export class DashboardSmsComponent implements OnInit {
	@Input() type;
	screen: 'dashboard' | 'booking' | 'flight' | 'new' = 'dashboard';
	flightId: string;
	flight: Flight;
	bookingId: string;
	showAll: boolean = false;
	filter: string = 'all';
	filterActive: boolean = true;

	bookingConversations: any[];
	flightConversations: any[];
	conversations: any[];
	history = 0;
	historyBatch = 10;
	bookingConversationSubscription: Subscription;
	flightConversationSubscription: Subscription;
	unreadSms: number;
	unreadSmsSubscription;

	constructor(
		public dialog: MatDialog,
		private bookingService: BookingService,
		private smsService: SmsService,
		private router: Router,
		private flightService: FlightService
	) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.updateCurrentUrl();
			}
		});
	}
	ngOnDestroy(): void {
		if (this.bookingConversationSubscription) this.bookingConversationSubscription.unsubscribe();
		if (this.flightConversationSubscription) this.flightConversationSubscription.unsubscribe();
		if (this.unreadSmsSubscription) {
			this.unreadSmsSubscription.unsubscribe();
		}
	}

	ngOnInit(): void {
		// get sms count
		this.unreadSmsSubscription = this.smsService.getUnreadSmsCount().subscribe({
			next: (count) => {
				this.unreadSms = count;
			},
		});
		this.showMoreHistory();
		this.smsService.getUnreadSmsCount().subscribe(() => {
			// this.refetchConversations();
		});
		// Capture the initial URL when the component is initialized
		this.updateCurrentUrl();
	}
	private updateCurrentUrl(): void {
		const localUrl = this.router.url;
		// default screen is dashboard
		this.screen = 'dashboard';
		this.filter = 'all';
		if (localUrl.includes('booking/') && !localUrl.includes('merge') && !localUrl.includes('new')) {
			this.screen = 'booking';
			this.filter = 'booking';
			this.bookingId = localUrl.split('/')[2];
		}
		if (localUrl.includes('flight/') && !localUrl.includes('new')) {
			this.screen = 'flight';
			this.filter = 'flight';
			this.flightId = localUrl.split('/')[2];
			this.showFlightConversations(this.flightId);
		}
		if (localUrl.includes('new')) {
			this.screen = 'new';
			this.filter = 'all';
		}
		this.filterConversations();
	}

	toggleFilter() {
		this.filterConversations();
	}

	changeFilterActive(event) {
		this.filterActive = event;
	}

	filterConversations() {
		if (this.type === SmsConversationType.Boo && this.filter === 'all') {
			this.conversations = this.bookingConversations;
		}
		if (this.type === SmsConversationType.Boo && this.filter === 'unread') {
			this.showUnread();
		}
		if (this.flightId && this.filter === 'flight') {
			this.conversations = this.flightConversations;
		}
		if (this.bookingId && this.filter === 'booking' && this.screen !== 'new') {
			this.showBookingConversations(this.bookingId);
		}
		// if (this.type === SmsConversationType.Cre && this.filter === 'all') {
		// 	this.crewService.crewConversations(this.history).valueChanges.subscribe((result) => {
		// 		if (this.filter === 'all') {
		// 			this.conversations = result.data.crewConversations;
		// 		}
		// 	});
		// }
	}

	showUnread() {
		if (this.bookingConversationSubscription) this.bookingConversationSubscription.unsubscribe();
		this.history += this.historyBatch;
		// subscribe to booking conversations
		this.bookingConversationSubscription = this.bookingService.getBookingConversations(0, null, true).subscribe((bookingConversations) => {
			this.conversations = bookingConversations;
		});
	}

	showMoreHistory() {
		if (this.bookingConversationSubscription) this.bookingConversationSubscription.unsubscribe();
		this.history += this.historyBatch;
		// subscribe to booking conversations
		this.bookingConversationSubscription = this.bookingService.getBookingConversations(this.history).subscribe((bookingConversations) => {
			this.bookingConversations = bookingConversations;
			this.filterConversations();
		});
	}

	showFlightConversations(flightId: string) {
		if (flightId) {
			if (this.flightConversationSubscription) this.flightConversationSubscription.unsubscribe();
			// subscribe to booking conversations for the flight
			this.flightConversationSubscription = this.bookingService.getBookingConversations(0, flightId).subscribe((flightConversations) => {
				this.flightConversations = flightConversations;
				this.filterConversations();
			});
			this.flightService.get(flightId).valueChanges.subscribe((flightResult) => {
				this.flight = flightResult.data.flight;
			});
		}
	}

	showBookingConversations(bookingId: string) {
		if (bookingId) {
			this.bookingService.getBooking(bookingId).valueChanges.subscribe((result) => {
				this.conversations = [result.data.booking];
			});
		}
	}

	sendReminder() {
		this.dialog.open(DialogSmsComponent, {
			data: {
				all: true,
				smsTemplate: SmsType.ReminderSms,
				flight: this.flight,
			},
		});
	}
}
