{
	"name": "ui",
	"version": "2.0.0",
	"scripts": {
		"ng": "ng",
		"start": "ng serve",
		"hmr": "node --max-old-space-size=4096 ./node_modules/@angular/cli/bin/ng serve --configuration hmr",
		"prebuild": "npm version patch",
		"build": "ng build",
		"build-acc": "ng build --configuration acceptance",
		"build-prd": "ng build --configuration production && npm run sentry:sourcemaps",
		"test": "ng test",
		"lint": "ng lint",
		"e2e": "ng e2e",
		"sentry:sourcemaps": "sentry-cli sourcemaps inject --org upconsulting --project quick-release-ui ./dist && sentry-cli sourcemaps upload --org upconsulting --project quick-release-ui ./dist"
	},
	"private": true,
	"prettier": {
		"tabWidth": 2,
		"useTabs": true,
		"singleQuote": true,
		"printWidth": 140
	},
	"dependencies": {
		"@angular-eslint/schematics": "^18.3.0",
		"@angular/animations": "^18.2.13",
		"@angular/cdk": "^18.2.14",
		"@angular/common": "^18.2.13",
		"@angular/compiler": "^18.2.13",
		"@angular/core": "^18.2.13",
		"@angular/fire": "^18.0.1",
		"@angular/forms": "^18.2.13",
		"@angular/google-maps": "^18.2.13",
		"@angular/material": "^18.2.14",
		"@angular/platform-browser": "^18.2.13",
		"@angular/platform-browser-dynamic": "^18.2.13",
		"@angular/router": "^18.2.13",
		"@angular/youtube-player": "^18.2.1",
		"@apollo/client": "^3.11.4",
		"@ckeditor/ckeditor5-angular": "^8.0.0",
		"@ckeditor/ckeditor5-build-classic": "^43.0.0",
		"@iplab/ngx-color-picker": "^18.0.1",
		"@ncstate/sat-popover": "^13.0.2",
		"@ngx-translate/core": "^15.0.0",
		"@ngx-translate/http-loader": "^8.0.0",
		"@sentry/angular": "^8.27.0",
		"@sentry/cli": "^2.31.0",
		"@types/googlemaps": "^3.43.3",
		"@types/luxon": "^3.4.2",
		"@types/react": "^18.3.3",
		"angular-calendar": "^0.29.0",
		"angular-csv-ext": "^1.0.5",
		"apollo-angular": "^7.0.2",
		"bootstrap": "^5.3.3",
		"colorthief": "^2.2.0",
		"dayjs": "^1.11.11",
		"echarts": "^5.5.1",
		"graphql": "^16.9.0",
		"graphql-ws": "^5.16.0",
		"jspdf": "^2.5.1",
		"jspdf-autotable": "^3.8.2",
		"luxon": "^3.5.0",
		"madge": "^7.0.0",
		"marked": "^9.1.6",
		"ngx-echarts": "^18.0.0",
		"ngx-file-drop": "^16.0.0",
		"ngx-google-analytics": "^12.0.0",
		"ngx-markdown": "^18.0.0",
		"ngx-material-timepicker": "^13.1.1",
		"rxjs": "^7.8.1",
		"signature_pad": "^4.0.4",
		"subscriptions-transport-ws": "^0.9.19",
		"tslib": "^2.6.2",
		"zone.js": "^0.14.10"
	},
	"devDependencies": {
		"@angular-devkit/architect": "0.1802.0",
		"@angular-devkit/build-angular": "^18.2.12",
		"@angular/cli": "^18.2.12",
		"@angular/compiler-cli": "^18.2.13",
		"@angular/localize": "^18.2.13",
		"@angularclass/hmr": "^3.0.0",
		"@playwright/test": "^1.46.1",
		"@types/body-parser": "^1.19.5",
		"@types/jasmine": "~3.6.0",
		"@types/jasminewd2": "~2.0.3",
		"@types/node": "^22.10.2",
		"@types/ws": "^7.4.7",
		"angular-eslint": "18.3.0",
		"eslint": "^9.9.0",
		"firebase-tools": "^13.7.5",
		"fuzzy": "^0.1.3",
		"inquirer": "^6.2.2",
		"inquirer-autocomplete-prompt": "^1.0.1",
		"jasmine-core": "^5.1.2",
		"jasmine-spec-reporter": "~5.0.0",
		"jsonc-parser": "^3.0.0",
		"karma": "~6.3.4",
		"karma-chrome-launcher": "~3.1.0",
		"karma-coverage-istanbul-reporter": "~3.0.2",
		"karma-jasmine": "~4.0.0",
		"karma-jasmine-html-reporter": "^1.5.0",
		"open": "^7.0.3",
		"prettier": "2.2.1",
		"ts-node": "~8.3.0",
		"tslint": "~6.1.0",
		"typescript": "^5.5.4",
		"typescript-eslint": "^8.18.2"
	}
}
