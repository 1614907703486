<mat-accordion class="example-headers-align">
	<mat-expansion-panel [expanded]="expanded" #mep>
		<mat-expansion-panel-header style="background-color: #f5f5f5">
			<mat-panel-title>{{ 'FLIGHTS.FILTER.date_range_filter' | translate | capitalize }} </mat-panel-title>
			<mat-panel-description>
				<div *ngIf="selectedPeriod?.period?.startDate">
					<div *ngIf="selectedPeriod.type === 'custom'">
						{{ 'FLIGHTS.FILTER.from' | translate | capitalize }} {{ selectedPeriod?.period?.startDate | formatDate: 'ddd DD MMM YY' }}
						{{ 'FLIGHTS.FILTER.till' | translate }} {{ selectedPeriod?.period?.endDate | formatDate: 'ddd DD MMM YY' }} ({{
							'FLIGHTS.FILTER.excluded' | translate
						}})
					</div>
					<div *ngIf="selectedPeriod.type === 'month'">
						{{ selectedPeriod?.period?.startDate | formatDate: 'MMMM YYYY' }}
					</div>
					<div *ngIf="selectedPeriod.type === 'week'">
						{{ 'FLIGHTS.FILTER.week' | translate | capitalize }} {{ 'FLIGHTS.FILTER.from' | translate }}
						{{ selectedPeriod?.period?.startDate | formatDate: 'ddd DD MMM YY' }}
					</div>
					<div *ngIf="selectedPeriod.type === 'day'">
						{{ selectedPeriod?.period?.startDate | formatDate: 'dddd DD MMM YY' }}
					</div>
				</div>
				<div *ngIf="!selectedPeriod?.period?.startDate">{{ 'FLIGHTS.FILTER.no_date_filter_active' | translate | capitalize }}</div>
				<button
					(click)="$event.stopPropagation(); clearFilter(); mep.expanded = false"
					mat-icon-button
					[disabled]="!selectedPeriod?.period?.startDate"
				>
					<mat-icon [color]="selectedPeriod?.period?.startDate ? 'warn' : 'blank'">delete</mat-icon>
				</button>
			</mat-panel-description>
		</mat-expansion-panel-header>
		<div style="display: flex; gap: 20px; align-items: center; margin-top: 20px">
			<!-- filterRangeForm -->
			<mat-form-field appearance="outline" style="width: 320px">
				<mat-label>{{ 'FLIGHTS.FILTER.enter_a_date_range' | translate | capitalize }}</mat-label>
				<mat-date-range-input [formGroup]="filterRangeForm" [rangePicker]="picker">
					<mat-label>{{ 'FLIGHTS.FILTER.start_date' | translate | capitalize }}</mat-label>
					<input matStartDate (focus)="picker.open()" formControlName="startDate" [value]="filterRangeForm?.value?.startDate" />
					<mat-label>{{ 'FLIGHTS.FILTER.end_date' | translate | capitalize }}</mat-label>
					<input
						matEndDate
						(focus)="picker.open()"
						formControlName="endDate"
						(dateChange)="applyFilter()"
						[value]="filterRangeForm?.value?.endDate"
					/>
				</mat-date-range-input>
				<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-date-range-picker #picker></mat-date-range-picker>
			</mat-form-field>
		</div>
		<div class="filter-toolbar" *ngIf="showHistogram">
			<mat-radio-group class="filter-buttons" [(ngModel)]="filter" (change)="updateHistogram()">
				<mat-radio-button value="month">{{ 'FLIGHTS.FILTER.month' | translate | capitalize }}</mat-radio-button>
				<mat-radio-button value="week">{{ 'FLIGHTS.FILTER.week' | translate | capitalize }}</mat-radio-button>
				<mat-radio-button value="day">{{ 'FLIGHTS.FILTER.day' | translate | capitalize }}</mat-radio-button>
			</mat-radio-group>
			<!-- Vertical divider -->
			<mat-divider vertical style="height: 120px"></mat-divider>

			<!-- histogram -->
			<div style="display: flex; gap: 5px; align-items: end">
				@for (period of selectedFlightPeriod; track $index) {
				<div style="display: flex; flex-direction: column; gap: 5px; align-items: center">
					<div style="display: flex; gap: 0px">
						<div style="display: flex; flex-direction: column; justify-content: end; align-items: center; height: 80px">
							<div
								class="time-rectangle"
								[style.height.px]="(period.MOR.flightsCount / maxValues[histogramPeriod]) * maxHeight"
								style="background-color: #42a5f5; border: 1px solid #ccc"
								[matTooltip]="period.MOR.flightsCount + ' ' + ('FLIGHTS.FILTER.flights' | translate | capitalize)"
							>
								{{ period.MOR.flightsCount }}
							</div>
						</div>
						<div style="display: flex; flex-direction: column; justify-content: end; align-items: center; height: 80px">
							<div
								class="time-rectangle"
								[style.height.px]="(period.EVE.flightsCount / maxValues[histogramPeriod]) * maxHeight"
								style="background-color: #b71c1c; border: 1px solid #ccc"
								[matTooltip]="period.EVE.flightsCount + ' ' + ('FLIGHTS.FILTER.flights' | translate | capitalize)"
							>
								{{ period.EVE.flightsCount }}
							</div>
							<!-- <div
								class="time-rectangle"
								[style.height.px]="period.freeSpots / maxValue * maxHeight"
								style="background-color: white; border: 1px solid #ccc"
								[matTooltip]="period.freeSpots + ' available'"
							>
								
							</div>
							<div
								class="time-rectangle"
								[style.height.px]="period.passengersCountInvited  / maxValue * maxHeight"
								style="background-color: #B71C1C; border-right: 1px solid #ccc; border-left: 1px solid #ccc"
								*ngIf="period.passengersCountInvited"
								[matTooltip]="period.passengersCountInvited + ' invited'"
							>
							</div>
							<div
								class="time-rectangle"
								[style.height.px]="period.passengersCountAdded  / maxValue * maxHeight"
								style="background-color: #FFCA28; border-right: 1px solid #ccc; border-left: 1px solid #ccc"
								*ngIf="period.passengersCountAdded"
								[matTooltip]="period.passengersCountAdded + ' added'"
							>
							</div>
							<div
								class="time-rectangle"
								[style.height.px]="period.passengersCountConfirmed  / maxValue * maxHeight"
								style="background-color: #1FAA00;border-right: 1px solid #ccc; border-left: 1px solid #ccc"
								*ngIf="period.passengersCountConfirmed"
								[matTooltip]="period.passengersCountConfirmed + ' confirmed'"
							>
							</div> -->
						</div>
					</div>
					<div>{{ period.flightsCount }}</div>
					<button
						mat-stroked-button
						[ngStyle]="{ 'background-color': period.label === selectedPeriod?.period.label ? '#42a5f5' : 'white' }"
						(click)="selectPeriod(filter, period)"
					>
						{{ translatePeriodLabel(period.label) | async }}
					</button>
				</div>
				}
			</div>
		</div>
	</mat-expansion-panel>
</mat-accordion>
