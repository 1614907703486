import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-sms-status-icon',
	templateUrl: './sms-status-icon.component.html',
	styleUrls: ['./sms-status-icon.component.scss'],
})
export class SmsStatusIconComponent implements OnInit {
	@Input() status: string;
	@Input() error: string;

	constructor() {}

	ngOnInit(): void {}
}
