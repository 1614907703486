import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserRole } from 'src/app/core/graphql/generated/gen-types';
import { AuthenticationService, UserService } from 'src/app/core/services';
import { ResponsiveService } from 'src/app/core/services/admin/responsive.service';
import { SmsService } from 'src/app/core/services/sms/sms.service';
import { TenantSettingService } from 'src/app/core/services/tenantSetting/tenant-setting.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
	@Output() toggleMenu = new EventEmitter<any>();
	guards = environment.componentGuards.toolbar;
	userRole = UserRole;
	tenant;
	viewMobile: boolean = true;
	isOperator: boolean = false;
	unreadSms: number = 0;
	currentAppVersion: string;
	baseHelpUrl = environment.baseHelpUrl;
	unreadSmsSubscription: Subscription;
	drawerStatus: boolean = false;

	constructor(
		private tenantSettingService: TenantSettingService,
		private authenticationService: AuthenticationService,
		private responsiveService: ResponsiveService,
		private router: Router,
		private titleService: Title,
		private userService: UserService,
		private smsService: SmsService
	) {
		this.currentAppVersion = environment.appVersion;
	}

	ngOnInit(): void {
		// get sms count
		this.unreadSmsSubscription = this.smsService.getUnreadSmsCount().subscribe({
			next: (count) => {
				this.unreadSms = count;
			},
		});
		this.smsService.getSmsDrawerStatus().subscribe((status) => {
			this.drawerStatus = status;
		});
		this.responsiveService.viewMobileChanged.subscribe((viewMobile) => {
			this.viewMobile = viewMobile;
		});

		this.userService.userProfileChanged.subscribe((userProfile) => {
			this.userRole = userProfile.role;
			this.isOperator = this.guards.operator.includes(userProfile.role);
		});

		this.tenantSettingService.getTenantSetting().valueChanges.subscribe((result) => {
			this.tenant = result.data.tenantSetting.name;
			this.updateTitle();
		});
	}

	toggleDrawerStatus() {
		this.smsService.setSmsDrawerStatus(!this.drawerStatus);
	}

	updateTitle() {
		const env = environment.env.toUpperCase();
		const tenantName = this.tenant?.toUpperCase();
		let title = 'QR';
		if (env !== 'PROD') {
			title += ` - ${env}`;
		}
		if (tenantName) {
			title += ` - ${tenantName}`;
		}
		this.titleService.setTitle(title);
	}

	ngOnDestroy(): void {
		if (this.unreadSmsSubscription) {
			this.unreadSmsSubscription.unsubscribe();
		}
	}

	logout() {
		this.authenticationService.logout();
		this.router.navigate(['/account/login']);
	}

	getHelp() {
		window.open(this.baseHelpUrl, '_blank');
	}

	showReleaseNotes() {
		window.open(this.baseHelpUrl + '/release-notes/', '_blank');
	}
}
