<div *ngIf="listView" style="margin: 5px">
	<div *ngIf="totalUnread() > 100" class="sms-message" (click)="markSMSesAsReadAll(conversations)">
		<div style="display: flex; justify-content: center">
			<span style="color: #42a5f5"
				><strong>{{ totalUnread() }}</strong></span
			>
			<mat-icon class="mat-accent-icon">sms</mat-icon>
			{{ 'COMMUNICATIONS.mark' | translate | capitalize }} {{ totalUnread() }} {{ 'COMMUNICATIONS.messages_as_read' | translate }}
		</div>
	</div>
	<mat-divider *ngIf="totalUnread() > 100"></mat-divider>

	@for (conversation of conversations; track $index) {
	<div
		class="sms-message"
		(click)="showSelectedSMS(conversation)"
		[matBadge]="conversation?.smsUnread"
		[matBadgeHidden]="conversation?.smsUnread === 0"
		matBadgeColor="accent"
	>
		<div style="display: flex; justify-content: space-between; align-items: center">
			<div style="display: flex; flex-direction: column" *ngIf="conversation?.smsType === 'BOO'">
				<strong>{{ conversation?.contactName }}</strong>
			</div>
			<div style="display: flex; flex-direction: column" *ngIf="conversation?.smsType === 'CRE'">
				<strong>{{ conversation?.name }}</strong>
			</div>
			<div style="display: flex; flex-direction: column; font-size: 11px">{{ formatDate(conversation?.smsLatest?.date) }}</div>
		</div>
		<div style="display: flex; justify-content: space-between; align-items: center">
			<div style="display: flex">
				<span *ngIf="conversation?.smsLatest?.direction === 'OUT'" style="font-style: italic"
					><strong>{{ 'COMMUNICATIONS.you' | translate | capitalize }}:&nbsp;</strong></span
				>
				{{ conversation?.smsLatest?.summary }}
			</div>
			<app-sms-status-icon
				*ngIf="conversation?.smsLatest?.direction === 'OUT'"
				[status]="conversation?.smsLatest?.status"
				[error]="conversation?.smsLatest?.error"
			></app-sms-status-icon>
		</div>
	</div>
	}
</div>
<div *ngIf="!listView" style="margin: 5px">
	<!-- Detail header -->
	<div style="display: flex; justify-content: space-between; align-items: center">
		<div style="display: flex; flex-direction: column; justify-content: space-between; align-items: center; min-width: 20px">
			<button mat-icon-button (click)="showSMSList(selectedConversation)">
				<mat-icon>keyboard_arrow_left</mat-icon>
			</button>
		</div>
		<div style="display: flex; flex-direction: column; justify-content: space-between; align-items: center; min-width: 60px">
			<div>
				<strong>{{ selectedConversation?.name }}</strong>
			</div>
			<div>{{ selectedConversation?.phone }}</div>
		</div>
		<div style="display: flex; flex-direction: column; justify-content: space-between; align-items: center; min-width: 20px">
			<div style="display: flex; align-items: center" *ngIf="selectedConversation?.smsUnread != 0">
				<mat-icon class="mat-accent-icon" [matBadge]="selectedConversation?.smsUnread" matBadgeColor="warn" aria-hidden="false"
					>sms</mat-icon
				>
			</div>
			<div
				style="display: flex; align-items: center"
				*ngIf="selectedConversation?.smsLatest?.direction === 'OUT' && selectedConversation?.smsUnread === 0"
			>
				<mat-icon class="mat-error-icon">sms_failed</mat-icon>
			</div>
		</div>
	</div>
	<mat-divider></mat-divider>
	<div *ngIf="selectedConversation?.smsType === 'BOO'" style="display: flex; justify-content: space-between; margin-top: 10px">
		<button [disabled]="!selectedBooking" mat-flat-button (click)="viewDetailsBooking(selectedBooking)">
			<mat-icon>perm_contact_calendar</mat-icon>{{ selectedBooking?.passengerCounters?.passengerCount }} PAX
		</button>
		<button *ngIf="selectedBooking?.flight?.date" mat-flat-button (click)="viewDetailsFlight(selectedBooking)">
			<mat-icon>flight_takeoff</mat-icon>{{ selectedBooking?.flight?.date | formatDate: 'ddd DD MMM' }}
		</button>
	</div>

	<!-- reply form -->
	<div style="margin: 10px 0px; background-color: #f0f0f0">
		<div style="display: flex; justify-content: space-between; align-items: center; padding: 5px">
			<mat-form-field appearance="outline" style="width: 100%">
				<mat-label>{{ 'FLIGHTS.reply' | translate | capitalize }}</mat-label>
				<textarea cdkAutosizeMinRows="2" cdkTextareaAutosize matInput name="sms" [(ngModel)]="replyText"></textarea>
				<mat-hint style="font-size: 11">{{ characterCount(replyText) }} chars left - {{ smsCount(replyText) }} sms</mat-hint>
			</mat-form-field>
			<button mat-icon-button (click)="smsReply(selectedConversation)">
				<mat-icon>send</mat-icon>
			</button>
		</div>
		<div
			*ngIf="upEnabled && selectedConversation?.smsType === 'BOO'"
			style="display: flex; justify-content: space-between; align-items: center; padding: 5px"
		>
			<mat-slide-toggle [(ngModel)]="smsUp">{{ 'FLIGHTS.send_up_url_with_sms' | translate | capitalize }}</mat-slide-toggle>
		</div>
	</div>
	<mat-divider></mat-divider>
	<!-- messages -->
	<div *ngIf="messages?.length != 0">
		@for (message of messages; track $index; let i = $index) {
		<div *ngIf="i === 0" style="display: flex; justify-content: center; align-items: center" class="sms-date">
			{{ formatDate(message?.date?.sms) }}
		</div>
		<div *ngIf="i > 0">
			<div
				style="display: flex; justify-content: center; align-items: center"
				class="sms-date"
				*ngIf="formatDate(message?.date?.sms) != formatDate(messages[i - 1]?.date?.sms)"
			>
				{{ formatDate(message?.date?.sms) }}
			</div>
		</div>
		<div style="display: flex; justify-content: end; align-items: center; gap: 10px" *ngIf="message?.direction === 'OUT'">
			<div class="sms-message sms-message-out">
				<div style="display: flex; justify-content: space-between; align-items: center; gap: 10px">
					<app-sms-status-icon [status]="message?.eventSummary?.status" [error]="message?.eventSummary?.error"></app-sms-status-icon>
					<div style="text-align: right; word-wrap: break-word; width: 190px">
						<span style="font-style: italic"
							><strong>{{ 'COMMUNICATIONS.you' | translate | capitalize }}:&nbsp;</strong></span
						>
						{{ message?.text }}
					</div>
				</div>
			</div>
		</div>
		<div style="display: flex; align-items: center; gap: 10px" *ngIf="message?.direction === 'IN'">
			<div class="sms-message sms-message-in">
				<div style="text-align: left; word-wrap: break-word" [ngStyle]="{ color: message?.date?.read ? '' : '#42A5F5' }">
					{{ message?.text }}
				</div>
			</div>
		</div>
		}
	</div>
</div>
