<h2 mat-dialog-title>{{ 'APPLICATION.send_email' | translate | capitalize }}</h2>
<mat-dialog-content>
	<div style="display: flex; flex-direction: column; gap: 20px; max-height: 100vh; overflow-y: auto">
		<mat-card appearance="outlined">
			@if (data.emails.length > 1) {
			<mat-card-header *ngIf="!readOnly">
				<mat-card-title>
					{{ 'APPLICATION.send_email_to' | translate | capitalize }}
				</mat-card-title>
			</mat-card-header>
			<mat-chip-set style="margin-left: 20px">
				@for (email of data.emails; track $index; let i = $index) {
				<mat-chip (click)="selectMail(i)" [selected]="i === selectedIndex">
					{{ email.to }}
				</mat-chip>
				}
			</mat-chip-set>
			<mat-divider></mat-divider>
			}
			<mat-card-header *ngIf="readOnly">
				<mat-card-title>
					{{ 'APPLICATION.send_email_to' | translate | capitalize }}
				</mat-card-title>
				<mat-card-subtitle *ngIf="mailMessage.result">
					{{ 'APPLICATION.' + mailMessage.result | translate | capitalize }}
					{{ mailMessage.resultAt | formatDate: 'dd DD-MMM-YYYY HH:mm:ss':false }}
				</mat-card-subtitle>
				<mat-card-subtitle *ngIf="!mailMessage.result">
					{{ 'APPLICATION.waiting' | translate | capitalize }}
				</mat-card-subtitle>
				<mat-divider></mat-divider>
			</mat-card-header>
			<mat-card-content class="mat-card-content" *ngIf="data.emails.length > 0">
				<!-- MAIL Form -->
				<div style="display: flex; flex-direction: column; justify-content: space-between; gap: 16px; margin-top: 16px">
					<!-- <div *ngIf="!readOnly">
						<button mat-flat-button [disabled]="!fileUploadFinished() || !validFileSize()" (click)="send()" color="accent">
							<mat-icon>send</mat-icon>{{ 'FLIGHTS.send' | translate | capitalize }}
						</button>
					</div> -->
					<form [formGroup]="mailDetailForm">
						<mat-form-field appearance="outline" style="width: 100%; min-width: 200px">
							<mat-label>{{ 'SETTINGS.emailTo' | translate | capitalize }}</mat-label>
							<textarea [readonly]="readOnly" cdkTextareaAutosize matInput cdkAutosizeMinRows="1" formControlName="to"></textarea>
						</mat-form-field>
						<mat-form-field appearance="outline" style="width: 100%; min-width: 200px">
							<mat-label>{{ 'SETTINGS.emailSubject' | translate | capitalize }}</mat-label>
							<textarea [readonly]="readOnly" cdkTextareaAutosize matInput cdkAutosizeMinRows="1" formControlName="subject"></textarea>
						</mat-form-field>
					</form>
					<div *ngIf="!readOnly">
						<!-- attachments -->
						@for (item of mailAttachments; track item; let i = $index) {
						<div style="display: flex; align-items: center; gap: 10px">
							<mat-icon>attach_file</mat-icon>
							<div style="display: flex; flex-direction: column">
								{{ item.filename }}
								<mat-progress-bar mode="determinate" [value]="item.progress"></mat-progress-bar>
								<mat-hint *ngIf="item.size">{{ item.size }} kB</mat-hint>
							</div>
							<button *ngIf="!readOnly" mat-icon-button (click)="removeAttachment(item)">
								<mat-icon [color]="item.valid ? 'primary' : 'warn'">clear</mat-icon>
							</button>
							<span *ngIf="!item.valid">{{ 'VALIDATIONS.invalid_file_you_can_only_upload_pdf_of_max_2MB' | translate }}</span>
						</div>
						}
						<div *ngIf="!validFileSize()">
							{{
								'VALIDATIONS.the_total_size_of_all_attachments_exceeds_10BM_please_remove_1_or_more_attachments' | translate | capitalize
							}}
						</div>
						<ngx-file-drop
							dropZoneClassName="dropzone"
							contentClassName="dropzone-content"
							(onFileDrop)="dropped($event)"
							[directory]="false"
						>
							<ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
								<div style="display: flex; gap: 20px; justify-content: space-between; align-items: center; margin: 10px">
									<mat-icon>save_alt</mat-icon>
									<span>{{ 'SETTINGS.drag_and_drop_file_here' | translate | capitalize }}</span>
									<button mat-flat-button color="accent" (click)="openFileSelector()">
										{{ 'SETTINGS.browse_for_file' | translate | capitalize }}
									</button>
								</div>
							</ng-template>
						</ngx-file-drop>
					</div>
					<div *ngIf="readOnly">
						<mat-card-subtitle *ngIf="mailMessage?.attachments?.length > 0">
							{{ 'SETTINGS.emailAttachments' | translate | capitalize }}
						</mat-card-subtitle>
						@for (item of mailMessage?.attachments; track item; let i = $index) {
						<div style="display: flex; align-items: center; gap: 10px">
							<mat-icon>attach_file</mat-icon>
							<div style="display: flex; flex-direction: column">
								{{ item.filename }}
							</div>
						</div>
						}
					</div>
					<!-- body -->
					<div *ngIf="!readOnly" style="display: flex; flex-direction: column; justify-content: space-between; gap: 16px">
						<div style="width: 100%; min-width: 600px">
							<ckeditor [editor]="bodyEditor" [(ngModel)]="editorData" (change)="onChangeBody($event)" [config]="config"> </ckeditor>
						</div>
					</div>
					<div *ngIf="readOnly">
						<mat-card-subtitle>
							{{ 'SETTINGS.emailBody' | translate | capitalize }}
						</mat-card-subtitle>
						<div style="width: 100%; min-width: 600px" [innerHTML]="editorData"></div>
					</div>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	@if (!readOnly) {
	<button mat-flat-button [disabled]="!fileUploadFinished() || !validFileSize()" (click)="send()" color="accent">
		<mat-icon>send</mat-icon>{{ 'FLIGHTS.send' | translate | capitalize }}
	</button>
	}

	<button mat-stroked-button (click)="cancel()">{{ 'APPLICATION.cancel' | translate | capitalize }}</button>
</mat-dialog-actions>
