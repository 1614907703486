<mat-chip-listbox [(ngModel)]="tagsSelected" multiple="true" [disabled]="disableAll">
	@for (tag of tags; track $index) {
	<mat-chip-option
		*ngIf="upcoming ? tag.upcoming : tag.history"
		[disabled]="disableAll"
		[value]="tag"
		[ngStyle]="{
			'background-color': tag.backgroundColor || 'initial',
			color: tag.fontColor || 'initial',
			cursor: disableAll ? 'not-allowed' : 'pointer'
		}"
		(click)="!disableAll && selectTag()"
	>
		<div *ngIf="upcoming === undefined">{{ tag.tag }}</div>
		<div *ngIf="upcoming !== undefined">{{ tag.tag }} ({{ upcoming ? tag.upcoming : tag.history }})</div>
	</mat-chip-option>
	}
</mat-chip-listbox>
