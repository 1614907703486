<div style="display: flex; flex-direction: column; gap: 5px">
	@for (booking of bookings; track booking) {
	<button mat-stroked-button (click)="navigateToBooking(booking.id)">
		<span [ngStyle]="findFilter(booking) ? { 'background-color': 'yellow' } : {}">
			<div
				*ngIf="booking.status != 'CONF' && booking.status != 'COMP'"
				style="display: flex; justify-content: space-between; align-items: center; gap: 5px"
			>
				<span>({{ booking.passengers?.length }}) {{ booking.contactName }}</span>
				<mat-icon [ngClass]="booking.iconColor === 'accent' ? 'mat-accent-icon' : ''">{{ booking.icon }}</mat-icon>
			</div>
			<span *ngIf="booking.status === 'CONF'">({{ booking.passengers?.length }}) {{ booking.contactName }}</span>
			<span *ngIf="booking.status === 'COMP'">({{ booking.passengers?.length }}) {{ booking.contactName }}</span>
		</span>
	</button>
	}
</div>
