import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideFirebaseApp, initializeApp as initializeAngularFireApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions } from '@angular/fire/functions';

import { CUSTOM_DATE_FORMAT } from './shared/shared.module';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { GraphQLModule } from './graphql.module';
import { environment } from 'src/environments/environment';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { MarkdownModule } from 'ngx-markdown';
import { DayjsDateAdapter } from './core/adapters/dayjs-date.adapter';

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [AppComponent],
	exports: [AppComponent],
	bootstrap: [AppComponent],
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		GraphQLModule,
		MarkdownModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
			defaultLanguage: 'en',
			isolate: false,
			extend: true,
		}),
	],
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
		{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT },
		{ provide: DateAdapter, useClass: DayjsDateAdapter },
		provideHttpClient(withInterceptorsFromDi()),
		provideFirebaseApp(() => initializeAngularFireApp(environment.firebase)),
		provideAuth(() => getAuth()),
		provideFirestore(() => getFirestore()),
		provideFunctions(() => {
			const functions = getFunctions();
			functions.region = 'europe-west1';
			return functions;
		}),
		provideStorage(() => getStorage()),
	],
})
export class AppModule {}
