import { Injectable } from '@angular/core';
import { Storage, ref, deleteObject, UploadResult, uploadBytes, UploadTask, uploadBytesResumable, percentage } from '@angular/fire/storage';
import * as uuid from 'uuid';
import { TenantSettingService } from '../tenantSetting/tenant-setting.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class FileService {
	basePath: string;
	constructor(private tenantSettingService: TenantSettingService, private storage: Storage) {
		this.tenantSettingService.getTenantSetting().valueChanges.subscribe((tenantSettingResult) => {
			this.basePath = 'attachments/' + tenantSettingResult.data.tenantSetting.tenantId;
		});
	}

	uploadFile(
		file: File
	): {
		uploadTask: UploadTask;
		percentageChanges: Observable<any>;
	} {
		const filename = uuid.v4() + '.pdf';
		const fullPath = `${this.basePath}/${filename}`;
		const storageRef = ref(this.storage, fullPath);
		const uploadTask = uploadBytesResumable(storageRef, file);

		return {
			uploadTask,
			percentageChanges: percentage(uploadTask),
		};
	}

	deleteFile(reference: string) {
		const fileRef = ref(this.storage, reference);
		return deleteObject(fileRef);
	}
}
