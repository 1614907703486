<h1 mat-dialog-title>{{ 'EVENTS.new_event' | translate | capitalize }}</h1>
<mat-dialog-content class="mat-typography">
	<p>{{ 'EVENTS.information' | translate | capitalize }}</p>
	<form [formGroup]="eventForm" novalidate autocomplete="off" style="margin: 20px">
		<mat-form-field appearance="outline" style="display: flex">
			<mat-label>{{ 'EVENTS.date' | translate | capitalize }}</mat-label>
			<input matInput [matDatepicker]="picker_date" formControlName="date" />
			<mat-datepicker-toggle matIconSuffix [for]="picker_date"></mat-datepicker-toggle>
			<mat-datepicker touchUi="true" #picker_date></mat-datepicker>
			<mat-error *ngIf="eventForm.get('date').hasError('required')">
				{{ 'EVENTS.required' | translate | capitalize }}
			</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label>{{ 'EVENTS.description' | translate | capitalize }}</mat-label>
			<input matInput type="text" name="name" formControlName="description" />
			<mat-error *ngIf="eventForm.get('description').hasError('required')">
				{{ 'EVENTS.required' | translate | capitalize }}
			</mat-error>
		</mat-form-field>
	</form>
</mat-dialog-content>
<div mat-dialog-actions>
	<button mat-flat-button [disabled]="!eventForm.valid" (click)="save(eventForm)">
		{{ 'EVENTS.save' | translate | capitalize }}
	</button>
	<button mat-flat-button (click)="cancel()">{{ 'EVENTS.cancel' | translate | capitalize }}</button>
</div>
<!-- <div style="margin-top: 60px">
	<div *ngIf="spinner" style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 5px">
		<mat-spinner></mat-spinner>
	</div>
	<div style="display: flex; flex-direction: column; gap: 5px">
		@for (list of holidays; track $index) {
		<button mat-flat-button color="accent" (click)="loadHolidays(list)">
			{{
				('EVENTS.load_public_holidays_of_year_from_country' | translate | capitalize).replace('{0}', list.year).replace('{1}', list.country)
			}}
		</button>
		}
	</div>
</div> -->
