import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Tag, TagType } from 'src/app/core/graphql/generated/gen-types';
import { TagService } from 'src/app/core/services/tag/tag.service';
import { SnackBar } from 'src/app/core/utility/snackBar';

@Component({
	selector: 'app-tag-select',
	templateUrl: './tag-select.component.html',
	styleUrls: ['./tag-select.component.scss'],
})
export class TagSelectComponent implements OnInit {
	@Input() disableAll = false;
	@Input() tagType: TagType;
	@Input() upcoming: any;
	@Output() selectedTags = new EventEmitter<Tag[]>();
	tags: Tag[];
	tagsSelected: Tag[] = [];

	constructor(private tagService: TagService, private snackBar: SnackBar) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.upcoming) {
			this.getData();
		}
	}

	ngOnInit(): void {
		this.getData();
	}

	getData(): void {
		if (this.tagType) {
			this.tagService.getTagsOfType(this.tagType, true, this.upcoming).valueChanges.subscribe(
				(result) => {
					const tags: Tag[] = this.tagService.sortByTagName(result.data.getTagsOfType);
					this.tags = tags;
				},
				(error) => {
					this.snackBar.openSnackBarError(error.message);
				}
			);
		} else {
			this.tagService.getAllTags(true).valueChanges.subscribe(
				(result) => {
					const tags: Tag[] = this.tagService.sortByTagName(result.data.getAllTags);
					this.tags = tags;
				},
				(error) => {
					this.snackBar.openSnackBarError(error.message);
				}
			);
		}
	}

	selectTag() {
		this.selectedTags.emit(this.tagsSelected);
		return;
	}
}
