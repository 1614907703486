import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from './../../../../environments/environment';
import { QR_USER } from '../../../constants';
import { Router } from '@angular/router';
import dayjs from 'dayjs';

export enum LoginError {
	UNKNOWN_TENANT = 'UNKNOWN_TENANT',
	INVALID_EMAIL = 'INVALID_EMAIL',
	INVALID_PASSWORD = 'INVALID_PASSWORD',
	CHECK_EMAIL = 'CHECK_EMAIL',
	OTHER = 'OTHER',
}
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
	private currentUserSubject: BehaviorSubject<any>;
	private loginErrorSubject: BehaviorSubject<any>;
	public currentUser: Observable<any>;
	public loginError: Observable<any>;
	public resultcode: any;
	public responseData: any = 'test';
	tenant;
	TOKEN_VALIDITY: number = 3599; // put 60 to test every minute

	constructor(private http: HttpClient, private router: Router) {
		this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem(QR_USER)));
		this.currentUser = this.currentUserSubject.asObservable();

		this.loginErrorSubject = new BehaviorSubject<string>(null);
		this.loginError = this.loginErrorSubject.asObservable();
	}

	public get currentUserValue() {
		return this.currentUserSubject.value;
	}

	public get accessToken() {
		return JSON.parse(localStorage.getItem(QR_USER))['accessToken'];
	}

	public get expired() {
		const expires = JSON.parse(localStorage.getItem(QR_USER))['expires'];
		return dayjs().diff(expires, 'seconds') > 0;
	}

	public get expiredRefreshToken() {
		const expires = JSON.parse(localStorage.getItem(QR_USER))['expires'];
		return dayjs().diff(expires, 'days') > 30;
	}

	refreshToken() {
		return JSON.parse(localStorage.getItem(QR_USER))['refreshToken'];
	}

	public get authenticated() {
		return !!localStorage.getItem(QR_USER);
	}

	public async login(tenantId: string, email: string, password: string) {
		return this.getToken(tenantId, email, password);
	}

	newLoginError(message) {
		this.loginErrorSubject.next(message);
	}

	// public get deviceFingerprint(): string {
	// 	let deviceFingerprint = '';
	// 	// get from session storage if already generated
	// 	const fingerprintFromSession = sessionStorage.getItem('deviceFingerprint');
	// 	if (fingerprintFromSession) {
	// 		deviceFingerprint = fingerprintFromSession;
	// 	} else {
	// 		// user browser, screen, timzone and language, canvas and webgl fingerprint
	// 		const browserDetails = `${navigator.userAgent} ${navigator.vendor} ${navigator.platform}`;
	// 		// user screen details
	// 		const screenDetails = `${window.screen.width}x${window.screen.height}x${window.screen.colorDepth}`;
	// 		// user timezone and language
	// 		const timezoneAndLanguage = `${Intl.DateTimeFormat().resolvedOptions().timeZone} ${navigator.language}`;
	// 		// user canvas fingerprint
	// 		const canvas = document.createElement('canvas');
	// 		const ctx = canvas.getContext('2d');
	// 		if (ctx) {
	// 			ctx.textBaseline = 'top';
	// 			ctx.font = '16px Arial';
	// 			ctx.textBaseline = 'alphabetic';
	// 			ctx.fillStyle = '#f60';
	// 			ctx.fillRect(125, 1, 62, 20);
	// 			ctx.fillStyle = '#069';
	// 			ctx.fillText('Hello, world!', 2, 15);
	// 			ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
	// 			ctx.fillText('Hello, world!', 4, 17);
	// 		}
	// 		const canvasFingerprint = canvas.toDataURL();
	// 		// user webgl fingerprint
	// 		const canvaswebgl = document.createElement('canvas');
	// 		const gl = (canvaswebgl.getContext('webgl') || canvaswebgl.getContext('experimental-webgl')) as WebGLRenderingContext;
	// 		let webGLFingerprint = 'unknown';
	// 		if (gl) {
	// 			const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
	// 			const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
	// 			const vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
	// 			webGLFingerprint = `${renderer} ${vendor}`;
	// 		}
	// 		const fingerprint = `${browserDetails} ${timezoneAndLanguage} ${canvasFingerprint} ${webGLFingerprint}`;
	// 		// hashing the fingerprint
	// 		const hash = require('object-hash');
	// 		const hashedFingerprint = hash(fingerprint);
	// 		// store the fingerprint in the session storage
	// 		sessionStorage.setItem('deviceFingerprint', hashedFingerprint);
	// 		deviceFingerprint = hashedFingerprint;
	// 	}
	// 	return deviceFingerprint;

	// }

	public getToken(tenantId: string, email: string, password: string) {
		const url = environment.identityPlatformUrl;
		const suffix = environment.identityPlatformSignInSuffix;
		const key = environment.identityPlatformApiKey;
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
		});
		this.http
			.post<any>(
				`${url}${suffix}${key}`,
				{
					tenantId: tenantId,
					email: email,
					password: password,
					returnSecureToken: true,
				},
				{ headers }
			)
			.subscribe(
				(response) => {
					this.doLoginUser(tenantId, response.email, response.idToken, response.refreshToken, response.localId, response.displayName);
				},
				(err) => {
					if (err.error.error.message === 'INVALID_EMAIL' || err.error.error.message === 'EMAIL_NOT_FOUND') {
						this.newLoginError(LoginError.INVALID_EMAIL);
					} else if (err.error.error.message === 'INVALID_PASSWORD') {
						this.newLoginError(LoginError.INVALID_PASSWORD);
					} else {
						this.newLoginError(err.error.error.message);
					}
				}
			);
	}

	//send send an api request to reset the password for the tennant with this id, mail will be send to this Email
	public sendResetPasswordMail(email: String, tenantId: string): Observable<any> {
		const url = environment.identityPlatformUrl;
		const suffix = environment.identityPlatformResetPasswordSuffix;
		const key = environment.identityPlatformApiKey;
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
		});
		return this.http.post<any>(
			`${url}${suffix}${key}`,
			{
				requestType: 'PASSWORD_RESET',
				email: email,
				tenantId: tenantId,
			},
			{ headers }
		);
	}

	public async getNewToken() {
		const url = environment.identityPlatformUrl;
		const suffix = environment.identityPlatformRefreshTokenSuffix;
		const key = environment.identityPlatformApiKey;
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
		});

		const response = await this.http
			.post<any>(
				`${url}${suffix}${key}`,
				{
					grant_type: 'refresh_token',
					refresh_token: this.refreshToken(),
				},
				{ headers }
			)
			.toPromise()
			.then((response) => {
				const user = JSON.parse(localStorage.getItem(QR_USER));
				user.accessToken = response.access_token;
				user.refreshToken = response.refresh_token;
				user.expires = dayjs().add(this.TOKEN_VALIDITY, 'seconds');
				localStorage.setItem(QR_USER, JSON.stringify(user));
			})
			.catch((err) => {
				this.logout();
			});
		return response;
	}

	private doLoginUser(tenantId: string, email: string, accessToken: string, refreshToken: string, userRef: string, displayName: string) {
		const user = {
			accessToken: accessToken,
			refreshToken: refreshToken,
			email: email,
			tenantId: tenantId,
			userRef,
			displayName,
			expires: dayjs().add(this.TOKEN_VALIDITY, 'seconds'),
		};
		localStorage.setItem(QR_USER, JSON.stringify(user));
		localStorage.removeItem('_QR_CURRENT_URL');
		this.currentUserSubject.next(user);
		this.newLoginError(null);
	}

	logout() {
		localStorage.removeItem(QR_USER);
		this.currentUserSubject.next(null);
		this.router.navigate(['/account/login']);
	}
}
