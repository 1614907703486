import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import {
	SendBookingSmsDocument,
	GetAllSmsTemplatesDocument,
	MarkBookingMessagesReadDocument,
	SmsByCrewDocument,
	GetSmsTemplatesDocument,
	SmsTemplateInput,
	SaveSmsTemplateDocument,
	SendCrewSmsDocument,
	MarkCrewMessagesReadDocument,
	AddSmsTemplateLocationContactDocument,
	SendLocationSmsDocument,
	OrderDirection,
} from '../../graphql/generated/gen-types';
import { AuthenticationService } from '../authentication/authentication.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { collection, collectionGroup, Firestore, onSnapshot, orderBy, query, where } from '@angular/fire/firestore';
import { TenantSettingService } from '../tenantSetting/tenant-setting.service';

@Injectable({
	providedIn: 'root',
})
export class SmsService {
	currentUser;
	// add subject to store the status of the smsDrawer
	public smsDrawerStatus = new BehaviorSubject<boolean>(false);

	constructor(
		private apollo: Apollo,
		private authenticationService: AuthenticationService,
		private tenantSettingService: TenantSettingService,
		private firestore: Firestore
	) {
		this.authenticationService.currentUser.subscribe((user) => {
			this.currentUser = user;
		});
	}

	// get smsDrawerStatus
	getSmsDrawerStatus(): Observable<boolean> {
		return this.smsDrawerStatus.asObservable();
	}

	// set smsDrawerStatus
	setSmsDrawerStatus(status: boolean) {
		this.smsDrawerStatus.next(status);
	}

	public saveSmsTemplate(smsTemplateInput: SmsTemplateInput, smsTemplateId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SaveSmsTemplateDocument,
				variables: {
					smsTemplateInput,
					smsTemplateId,
				},
			})
			.pipe(map((result: any) => result.data));
	}

	public sendBookingSMS(bookingId, smsInput): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SendBookingSmsDocument,
				variables: {
					bookingId,
					smsInput,
				},
			})
			.pipe(map((result: any) => result.data));
	}

	public sendLocationSMS(locationId, flightId, smsInput): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SendLocationSmsDocument,
				variables: {
					locationId,
					flightId,
					smsInput,
				},
			})
			.pipe(map((result: any) => result.data));
	}

	public sendCrewSms(crewId, smsInput): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SendCrewSmsDocument,
				variables: {
					crewId,
					smsInput,
				},
				refetchQueries: [
					{
						query: SmsByCrewDocument,
						variables: {
							crewId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public smsByCrew(crewId: string): QueryRef<any> {
		return this.apollo.watchQuery({
			query: SmsByCrewDocument,
			variables: {
				crewId,
			},
		});
	}

	public markBookingMessagesRead(bookingId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: MarkBookingMessagesReadDocument,
				variables: {
					bookingId,
				},
			})
			.pipe(map((result: any) => result.data));
	}
	public addSmsTemplateLocationContact(): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: AddSmsTemplateLocationContactDocument,
				refetchQueries: [
					{
						query: GetAllSmsTemplatesDocument,
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public markCrewMessagesRead(crewId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: MarkCrewMessagesReadDocument,
				variables: {
					crewId,
				},
				refetchQueries: [
					{
						query: SmsByCrewDocument,
						variables: { crewId },
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public getAllSmsTemplates(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetAllSmsTemplatesDocument,
		});
	}

	public getSmsTemplates(type: string): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetSmsTemplatesDocument,
			variables: {
				type,
			},
		});
	}

	// FIRESTORE QUERIES TO GET REALTIME SMS DATA
	private unreadSmsCountSubject: BehaviorSubject<number> | null = null;

	public getUnreadSmsCount(): Observable<number> {
		if (!this.unreadSmsCountSubject) {
			this.unreadSmsCountSubject = new BehaviorSubject<number>(0);

			this.tenantSettingService
				.getTenantSetting()
				.valueChanges.pipe(
					take(1),
					switchMap((result) => {
						let tenantId = result.data?.tenantSetting?.tenantId;
						if (tenantId) {
							const collectionGroupName = 'message';
							const constraints = [where('date.read', '==', null), where('tenantId', '==', tenantId), where('direction', '==', 'IN')];
							const collectionGroup_ = collectionGroup(this.firestore, collectionGroupName);
							const query_ = query(collectionGroup_, ...constraints);

							return new Observable<number>((observer) => {
								const unsubscribe = onSnapshot(
									query_,
									(snapshot) => {
										observer.next(snapshot.size);
									},
									(error) => {
										observer.error(error);
									}
								);
								return () => unsubscribe(); // Return unsubscribe function
							});
						} else {
							return of(0); // Handle cases where tenantId is null/undefined
						}
					}),
					shareReplay(1)
				)
				.subscribe(this.unreadSmsCountSubject);
		}
		return this.unreadSmsCountSubject.asObservable();
	}

	private smsByBookingCache: { [bookingId: string]: { observable: Observable<any[]>; unsubscribe: () => void } } = {};
	public smsByBooking(bookingId: string): Observable<any[]> {
		if (!this.smsByBookingCache[bookingId]) {
			const subject = new Subject<any[]>();

			const tenantSetting$ = this.tenantSettingService.getTenantSetting().valueChanges.pipe(take(1));
			const observable = tenantSetting$.pipe(
				switchMap((result) => {
					let tenantId = result.data?.tenantSetting?.tenantId;
					if (tenantId) {
						const collectionName = `/tenants/${tenantId}/booking/${bookingId}/message`;
						const collection_ = collection(this.firestore, collectionName);
						const constraints = [orderBy('date.sms', OrderDirection.Desc)];
						const query_ = query(collection_, ...constraints);

						return new Observable<any[]>((observer) => {
							const unsubscribe = onSnapshot(
								query_,
								(snapshot) => {
									const smsData = snapshot.docs.map((doc) => doc.data());
									const smsDataFiltered = [];

									smsData.forEach((sms) => {
										sms.date.sms = sms.date.sms.toDate(); // Assuming date.sms is a Timestamp

										if (sms.nexmo && sms.nexmo['concat'] === 'true') {
											const concatRef = sms.nexmo['concat-ref'];
											if (!smsDataFiltered.some((s) => s.nexmo && s.nexmo['concat-ref'] === concatRef)) {
												const concatenatedSms = smsData
													.filter((s) => s.nexmo && s.nexmo['concat-ref'] === concatRef)
													.sort((a, b) => a.nexmo['concat-part'] - b.nexmo['concat-part'])
													.reduce((acc, s, index) => {
														if (index === 0) {
															// Start with the first message as the base
															return { ...s, text: s.text };
														}
														// Append the text of subsequent messages
														return { ...acc, text: acc.text + s.text };
													}, null);
												smsDataFiltered.push(concatenatedSms);
											}
										} else {
											smsDataFiltered.push(sms);
										}
									});
									observer.next(smsDataFiltered);
								},
								(error) => observer.error(error)
							);

							return () => unsubscribe();
						});
					} else {
						return of([]);
					}
				}),
				shareReplay(1)
			);

			const subscription = observable.subscribe(subject);
			this.smsByBookingCache[bookingId] = { observable: subject.asObservable(), unsubscribe: () => subscription.unsubscribe() };
		}
		return this.smsByBookingCache[bookingId].observable;
	}

	public unsubscribeSmsByBooking(bookingId: string) {
		if (this.smsByBookingCache[bookingId]) {
			this.smsByBookingCache[bookingId].unsubscribe();
			delete this.smsByBookingCache[bookingId];
		}
	}
}
